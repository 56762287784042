import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../API";


export const generateSiteMapAction = createAsyncThunk(
    "common/generateSiteMapAction",
    async (data) => {
        return apiCall("/business/sitemap", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        // console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);



