import { createSlice } from "@reduxjs/toolkit";
import {
    generateSiteMapAction
} from "../actions/commonActions";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    geneateSiteMap: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const commonReducer = createSlice({
    name: "common",
    initialState: INITIAL_STATE,
    reducers: {

    },
    extraReducers: (builder) => {
        //------------------------------- loginAction start -------------------------
        // eslint-disable-next-line
        builder.addCase(generateSiteMapAction.fulfilled, (state, action) => {
            if(action.payload){
                state.geneateSiteMap.data = action.payload.data;
                state.geneateSiteMap.apiMsg.status = action.meta.requestStatus;
                state.geneateSiteMap.apiMsg.message = action.payload.message.message;
    
                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
           


        });

        builder.addCase(generateSiteMapAction.pending, (state, action) => {
            state.geneateSiteMap.data = null;
            state.geneateSiteMap.apiMsg.status = action.meta.requestStatus;
            

        });
        builder.addCase(generateSiteMapAction.rejected, (state, action) => {

            state.geneateSiteMap.data = null;
            state.geneateSiteMap.apiMsg.status = action.meta.requestStatus;
            if (action.payload && action.payload.message && action.payload.message.message) {
                state.geneateSiteMap.apiMsg.message = action.payload.message.message;
            }


            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: "bottom-center",
                toastId: "error"
            });
        });


    }
});

export const { } = commonReducer.actions;
export default commonReducer.reducer;
